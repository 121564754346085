import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import useBuilding from "../../hooks/useBuilding";
import { BuildingType } from "../../types";
import { capitalize } from "../../utils/stringUtils";
import useCustomQuery from "../../hooks/useCustomQuery";
import useWorkspace from "../../hooks/useWorkspace";

export default function MenuSelectBuilding() {
  const [menuDown, setMenuDown] = useState<boolean>(false);
  const { currentWorkspace } = useWorkspace();
  const { buildings, currentBuilding, getCurrentBuilding } = useBuilding();

  const queryBuildings = useCustomQuery(
    "buildings",
    `/workspaces/${currentWorkspace}/buildings/`,
    !!currentWorkspace
  );

  const toggle = () => {
    setMenuDown((prevState) => !prevState);
    if (!menuDown) {
      queryBuildings.refetch();
    }
  };

  const handleSelectedBuilding = (building: BuildingType) => {
    if (building) {
      getCurrentBuilding(building);
      setMenuDown(false);
    }
  };

  return (
    <div className="relative mt-1">
      <div
        onClick={toggle}
        className="bg-white flex items-center space-x-3 border border-stroke px-3 py-3 rounded-md cursor-pointer"
      >
        <div className="w-full flex items-center justify-between">
          <p className="text-[13px] text-gray-900">
            {currentBuilding ? (
              <span className="font-bold text-black/50">
                {capitalize(currentBuilding?.slug)}
              </span>
            ) : (
              "Select Building"
            )}
          </p>
        </div>
        <ChevronDownIcon
          className={`w-4 h-4 text-black/50 ${menuDown && "rotate-180"}`}
        />
      </div>
      <div
        className={`border border-stroke w-full rounded-md bg-white shadow-default ${
          menuDown ? "absolute z-999" : "hidden"
        }`}
      >
        {buildings?.map((building: BuildingType) => (
          <div
            key={building?.pkid}
            onClick={() => handleSelectedBuilding(building)}
            className={`w-full flex items-center justify-between py-4 text-[13px] border-b border-b-stroke text-meta-8 relative cursor-pointer select-none px-4`}
          >
            <p className="text-[13px]">{capitalize(building?.slug)}</p>
            {currentBuilding?.slug === building?.slug && (
              <CheckIcon className="w-3 h-3 text-primary_color" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
