import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TenantState, TenantType } from "../../../types";

const initialState: TenantState = {
  tenants: [],
  tenantToBeEdited: null,
  currentTenant: null,
  openTenantModal: false,
  openTenantModalDelete: false,
};

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    setTenants: (state, { payload }: PayloadAction<TenantType[]>) => {
      if (payload) {
        state.tenants = payload;
      }
    },
    setCurrentTenant: (state, { payload }: PayloadAction<TenantType>) => {
      if (payload) {
        state.currentTenant = payload;
      }
    },
    setTenant: (state, { payload }: PayloadAction<TenantType>) => {
      if (payload) {
        state.tenants = [...state.tenants, payload];
      }
    },
    updateTenant: (state, { payload }: PayloadAction<TenantType>) => {
      if (payload) {
        state.tenants = state.tenants.map((tenant) =>
          tenant.pkid === payload.pkid ? payload : tenant
        );
      }
    },
    deleteTenant: (state, { payload }: PayloadAction<TenantType>) => {
      if (payload) {
        state.tenants = state.tenants.filter(
          (tenant) => tenant.pkid !== payload.pkid
        );
      }
    },
    setTenantToBeEdited: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.tenantToBeEdited = payload;
      }
    },
    setTenantModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openTenantModal = payload;
    },
    setTenantModalDelete: (state, { payload }: PayloadAction<boolean>) => {
      state.openTenantModalDelete = payload;
    },
    clearCurrentTenant: (state) => {
      state.currentTenant = null;
    },
    clearTenants: (state) => {
      state.tenants = [];
    },
  },
});

export const {
  setTenant,
  setTenants,
  clearTenants,
  updateTenant,
  deleteTenant,
  setTenantModal,
  setCurrentTenant,
  clearCurrentTenant,
  setTenantToBeEdited,
  setTenantModalDelete,
} = tenantSlice.actions;

export default tenantSlice.reducer;
