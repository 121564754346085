import React, { useEffect, useRef } from "react";
import Button from "./Button";
import { PowerIcon, UserIcon } from "@heroicons/react/24/solid";
import useApp from "../../hooks/useApp";
import WorkspaceSelect from "../modals/WorkspaceSelect";
import useWorkspace from "../../hooks/useWorkspace";
import useAuth from "../../hooks/useAuth";
import { capitalize, getUsernameInitials } from "../../utils/stringUtils";
import useUnit from "../../hooks/useUnit";
import useBuilding from "../../hooks/useBuilding";
import useService from "../../hooks/useService";
import useProperty from "../../hooks/useProperty";
import useTenant from "../../hooks/useTenant";

const DropdownUser = () => {
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const { clearUnit } = useUnit();
  const { clearService } = useService();
  const { clearBuilding } = useBuilding();
  const { clearProperty } = useProperty();
  const { userInfo, signOut } = useAuth();
  const { menuDown, navigate, toggleMenu } = useApp();
  const { clearTenant: clearActiveTenant, clearTenant } = useTenant();
  const { currentWorkspace, workspaces, open, toggleOpen } = useWorkspace();

  const workspace = workspaces.find((ws) => ws.slug === currentWorkspace);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent): void => {
      if (!dropdown.current) return;
      if (
        !menuDown ||
        (dropdown.current as HTMLElement).contains(target as Node) ||
        (trigger.current &&
          (trigger.current as HTMLElement).contains(target as Node))
      )
        return;
      toggleMenu(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [menuDown, toggleMenu]);

  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent): void => {
      if (!menuDown || keyCode !== 27) return;
      toggleMenu(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [menuDown, toggleMenu]);

  const handleLogout = () => {
    signOut();
    clearUnit();
    clearTenant();
    clearService();
    clearProperty();
    clearBuilding();
    clearActiveTenant();
    navigate("/login");
    toggleMenu(false);
  };

  return (
    <div>
      <div
        ref={trigger}
        onClick={() => toggleMenu(!menuDown)}
        className="hover:bg-LIGHT_GREY cursor-pointer border h-10 w-10 rounded-md border-stroke transition ease-out duration-100"
      >
        <div className="h-full w-full bg-white hover:bg-background_color_second flex items-center justify-center rounded-md">
          {userInfo?.profile ? (
            <img
              src={userInfo?.profile}
              alt="profile"
              className="bg-cover h-full w-full rounded-md"
            />
          ) : (
            <p className="text-[13px] text-center">
              {getUsernameInitials(userInfo?.full_name)}
            </p>
          )}
        </div>
      </div>
      <div
        ref={dropdown}
        onFocus={() => toggleMenu(true)}
        onBlur={() => toggleMenu(false)}
        className={`absolute right-2 mt-3 z-99 flex w-62.5 bg-back2 bg-cover bg-no-repeat min-w-[16px] min-h-[16px] cursor-pointer flex-col transform transition-all duration-[198ms] backdrop-blur-xl rounded-lg border border-stroke bg-white/90 shadow-default ${
          menuDown === true
            ? "visible transform scale-100  ease-in duration-100 opacity-100"
            : "invisible transform scale-95  ease-out duration-100 opacity-0"
        }`}
      >
        <div
          onClick={() => navigate("/profile")}
          className="hover:bg-LIGHT_GREY flex items-center px-4 py-3 duration-300 ease-in-out transition"
        >
          <div className="mb-4">
            <UserIcon className="w-4 h-4 text-primary_color" />
          </div>
          <div className="flex flex-col mx-3">
            <div>
              <h3 className="font-semibold text-[13px] sm:text-[14.5px]">
                Account Profile
              </h3>
            </div>
            <div>
              <p className="text-[13px] sm:text-[14.5px] font-light">
                Signed as {userInfo?.full_name}
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            toggleOpen(true);
            toggleMenu(false);
          }}
          className="px-4 py-3 duration-300 ease-in-out transition relative hover:bg-brown-50"
        >
          {workspace ? (
            <div className="flex flex-col justify-center space-y-0">
              <p className="text-[11px] font-medium">Workspace</p>
              <p className="text-[13px] sm:text-[14.5px] font-light text-secondary_color">
                {capitalize(workspace.name)}
              </p>
            </div>
          ) : (
            <p className="text-[13px] sm:text-[14.5px] font-light">
              No Active workspace
            </p>
          )}
        </div>

        <div className="border-t border-stroke" />
        <Button
          onClick={handleLogout}
          icon={<PowerIcon className="w-4 h-4" />}
          text="Log out"
          small
          buttonStyle="hover:bg-LIGHT_GREY flex items-center gap-3.5 py-3 px-6 text-[13px] sm:text-[14.5px]  duration-300 ease-in-out"
        />
      </div>
      <WorkspaceSelect
        isOpen={open}
        close={() => toggleOpen(false)}
        clearBuilding={clearBuilding}
        clearUnitState={clearUnit}
        clearTenantState={clearTenant}
        clearServiceState={clearService}
      />
    </div>
  );
};

export default DropdownUser;
