import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { keyjaniApi, tokenConfig } from "../api";
import { useDispatch } from "react-redux";
import { setUserData } from "../redux/features/auth/authSlice";
import {
  setWorkspaceInvitations,
  setWorkspaceMembers,
  setWorkspaces,
} from "../redux/features/workspace/workSpaceSlice";
import { setBuildings } from "../redux/features/building/buildingSlice";
import { setUnits } from "../redux/features/units/unitSlice";
import { setServices } from "../redux/features/services/serviceSlice";
import { setTenants } from "../redux/features/tenant/tenantSlice";
import { setProperties } from "../redux/features/properties/propertySlice";

export default function useCustomQuery(
  getKey?: string,
  getUrl?: string,
  enabled: boolean = false
) {
  const dispatch = useDispatch();

  const queryOptions = {
    queryKey: [getKey],
    queryFn: () => keyjaniApi.get(`${getUrl}`, tokenConfig()),
    enabled: enabled,
    cacheTime: 0,
  };

  const query = useQuery(queryOptions);

  useEffect(() => {
    if (query && query.data?.data) {
      const data = query.data.data;

      switch (getKey) {
        case "user":
          if (getUrl === "/auth/profile/me/") {
            dispatch(setUserData(data));
          }
          break;
        case "workspaces":
          if (getUrl === "/workspaces/") {
            dispatch(setWorkspaces(data));
          }
          break;
        case "invitations":
          dispatch(setWorkspaceInvitations(data));
          break;
        case "members":
          dispatch(setWorkspaceMembers(data));
          break;
        case "buildings":
          dispatch(setBuildings(data));
          break;
        case "units":
          dispatch(setUnits(data));
          break;
        case "services":
          dispatch(setServices(data));
          break;
        case "tenants":
          dispatch(setTenants(data));
          break;
        case "rent-payments":
          return data;
        case "utility-payments":
          return data;

        default:
          dispatch(setProperties(data));
          break;
      }
    }
    return () => {};
  }, [dispatch, getKey, getUrl, query]);

  return query;
}
