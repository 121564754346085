import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import appReducer from "../redux/features/app/appSlice";
import authReducer from "../redux/features/auth/authSlice";
import workSpaceReducer from "../redux/features/workspace/workSpaceSlice";
import buildingReducer from "../redux/features/building/buildingSlice";
import unitReducer from "../redux/features/units/unitSlice";
import serviceReducer from "../redux/features/services/serviceSlice";
import imageReducer from "../redux/features/image/imageSlice";
import propertyReducer from "../redux/features/properties/propertySlice";
import alertReducer from "../redux/features/alert/alertSlice";
import tenantReducer from "../redux/features/tenant/tenantSlice";

const persistConfig = {
  key: "keyjani_dev_4",
  storage,
  whitelist: ["workspace", "building", "unit", "service", "tenant"],
};

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["userInfo"],
};

const workspacePersistConfig = {
  key: "workspace",
  storage,
  whitelist: ["currentWorkspace"],
};
const buildingPersistConfig = {
  key: "building",
  storage,
  whitelist: ["currentBuilding"],
};
const unitPersistConfig = {
  key: "unit",
  storage,
  whitelist: ["currentUnit"],
};
const servicePersistConfig = {
  key: "service",
  storage,
  whitelist: ["currentService"],
};

const tenantPersistConfig = {
  key: "tenant",
  storage,
  whitelist: ["currentTenant"],
};

const rootReducer = combineReducers({
  app: appReducer,
  image: imageReducer,
  alert: alertReducer,
  property: propertyReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  workspace: persistReducer(workspacePersistConfig, workSpaceReducer),
  building: persistReducer(buildingPersistConfig, buildingReducer),
  unit: persistReducer(unitPersistConfig, unitReducer),
  tenant: persistReducer(tenantPersistConfig, tenantReducer),
  service: persistReducer(servicePersistConfig, serviceReducer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
